<template>
    <div class="wheel-main">
        <div class="hedaer">
            <p>טירוף בונוסים</p>
        </div>
        <div class="wheel">
            <div class="sa">
                <div class="sa1">
                    <span class="spa">
                        <img :src="game_ops.items[0].image" alt="">
                    </span>
                    <span class="spb">
                        <img :src="game_ops.items[1].image" alt="">
                    </span>
                    <span class="spc">
                        <img :src="game_ops.items[2].image" alt="">
                    </span>
                    <span class="spd">
                        <img :src="game_ops.items[3].image" alt="">
                    </span>
                </div>
                <div class="sa2">
                    <span class="spa">
                        <img :src="game_ops.items[4].image" alt="">
                    </span>
                    <span class="spb">
                        <img :src="game_ops.items[5].image" alt="">
                    </span>
                    <span class="spc">
                        <img :src="game_ops.items[6].image" alt="">
                    </span>
                    <span class="spd">
                        <img :src="game_ops.items[7].image" alt="">
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { projectFirestore } from '../../firebase/config'
export default {
setup(){
    const game_ops = ref({
        items: [
            {
                makat: '1295',
                amount: 12,
                image: ' '
            },
            {
                makat: '1317',
                amount: 12,
                image: ' '
            },
            {
                makat: '1773',
                amount: 6,
                image: ' '
            },
            {
                makat: '2040',
                amount: 3,
                image: ' '
            },
            {
                makat: '2044',
                amount: 6,
                image: ' '
            },
            {
                makat: '1932',
                amount: 4,
                image: ' '
            },
            {
                makat: '1932',
                amount: 4,
                image: ' '
            },
            {
                makat: '1932',
                amount: 4,
                image: ' '
            },
        ],
        min_order: 1000,
        multiple: false,
        ready: false
    })

    const get_items = async () => {
        if(game_ops.value){
            for(let  i =0; i < game_ops.value.items.length; i++){
                let item = game_ops.value.items[i]
                const doc = await projectFirestore.collection('Products').doc(item.makat).get()
                if(doc.exists){
                    item.image = doc.data().image
                }
            }   
            game_ops.value.ready = true; 
        }
    }

    onMounted(() => {
        get_items();
    })

    return{
        game_ops
    }
}
}
</script>

<style scoped>
.wheel-main{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: url("https://wallpaperaccess.com/full/459724.png");
}
.hedaer{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90px;
    
}
.hedaer p{
    font-weight: 600;
    color: var(--light);
    text-shadow: 0 0 120px var(--success);
}
.wheel{
    position: relative;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 500px;
    background: white;
    border-radius: 50%;
}
.sa{
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    border: 8px solid var(--light);
    overflow: hidden;
}
.wheel span {
    width: 50%;
    height: 50%;
    display: inline-block;
    position: absolute;

}
.spa{
    position: relative;
    clip-path: polygon(0 95%, 100% 50%, 0 8%);
    background: green;
    top: 120px;
    left: 0;
}
.spb{
    clip-path: polygon(100% 95%, 0 50%, 100% 8%);
    background: blue;
    top: 120px;
    right: 0;
}
.spc{
    clip-path: polygon(50% 0%, 8% 100%, 95% 100%);
    background: pink;
    left: 120px;
    bottom: 0;
}
.spd{
    clip-path: polygon(50% 100%, 95% 0, 8% 0);
    background: black;
    top: 0px;
    left: 120px;
}
.sa2{
    height: 100%;
    width: 100%;
    transform: rotate(-136deg);
}
</style>